import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import {
    Link,
    useLocation
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    list: {
        width: 250,
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none'
    },
    logo: {
        width: 225,
    },
    media: {
        alignItems: "center",
        justify: "center",
        justifyContent: "center",
        marginTop: 10,
        marginBottom: 10,
        margin: 'auto',
        width: 225,
    }
}));

const Appbar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [toolbarTitle, setToolbarTitle] = useState('Thread Ninjas');
    const classes = useStyles();
    const toggleDrawer = () => setSidebarOpen(!sidebarOpen);
    let location = useLocation()

    useEffect(() => {
        let tempTitle = '';

        if (location.pathname.includes('about')) {
            tempTitle = 'About Us';
        }
        else {
            tempTitle = 'Home';
        }

        setToolbarTitle(tempTitle);
    }, [location]);

    return (
        <div className={classes.root}>
            <AppBar position="static" color='inherit'>
                <Toolbar>
                    <Link to="/" className={classes.link}>
                        <Button variant="text">Home</Button>
                    </Link>
                    <Link to="/about" className={classes.link}>
                        <Button variant="text">About</Button>
                    </Link>
                </Toolbar>
            </AppBar>
        </div >
    );
}

export default Appbar;
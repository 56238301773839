
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Logo from './images/logos/thread_ninjas.png';


const useStyles = makeStyles(() => ({
    media: {
        margin: 'auto',
        maxHeight: 500,
        maxWidth: 400,
    },
    textOffset: {
        marginLeft: 25
    },
    grid: {
        marginTop: 50,
        alignItems: "center",
        justify: "center",
        justifyContent: "center",
    }
}));

const AboutUs = () => {
    const classes = useStyles();

    return <>
        <Grid
            container
            spacing={5}
            direction="column"
            className={classes.grid}
        >
            <Grid item xs={12}>
                <img src={Logo} className={classes.media} alt='logo' />
            </Grid>
            <Grid item xs={12}>
                <Typography gutterBottom variant="h5" component="h2">
                    About us
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph={true}>
                    We sell vinyl-decorated tee shirts that are printed with love and care from our family-owned business in Celebration, Florida.
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph={true}>
                    Our child-artist CEO is dedicated to making the world a better place, and has decided to donate a portion of all sales to charitable organizations.
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph={true}>
                    Thank you for your support!
                </Typography>
            </Grid>
        </Grid>
    </>
}

export default AboutUs;
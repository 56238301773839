
import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Home from './Home';
import AboutUs from './AboutUs';
import Paper from '@material-ui/core/Paper';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Appbar from './Appbar';

import {
  useLocation,
  Switch,
  Route,
} from "react-router-dom";

const useStyles = makeStyles(() => ({
  main: {
    minWidth: '100vw',
    minHeight: '100vh',
    background: '#000000'
  },
}));


const App = () => {
  const classes = useStyles();

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          type: 'dark',
        },
      })
  );


  return (
    <ThemeProvider theme={theme} >
      <Paper className={classes.main}>
        <Appbar />
        <Switch>
          <Route path="/about" exact={true} >
            <AboutUs />
          </Route>
          <Route path="/" exact={true}>
            <Home />
          </Route>
        </Switch>
      </Paper>
    </ThemeProvider>
  );
}

export default App;



import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logo from './images/logos/thread_ninjas.png';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() => ({
    media: {
        margin: 'auto',
        maxHeight: 500,
        maxWidth: 400,
    },
    textOffset: {
        marginLeft: 25
    },
    grid: {
        marginTop: 50,
        alignItems: "center",
        justify: "center",
        justifyContent: "center",
    }
}));

const Home = () => {
    const classes = useStyles();

    return <Grid
        container
        spacing={5}
        direction="column"
        className={classes.grid}
    >
        <Grid item xs={12}>
            <img src={Logo} className={classes.media} alt='logo' />
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={5} xl={4} className={classes.textOffset}>
            <Typography gutterBottom variant="h5" component="h2" align="center" paragraph={true}>
                Coming Soon!
            </Typography>
            <Typography gutterBottom variant="h6" component="h2" align="center" paragraph={true}>
                Please visit our
                <Link href="https://www.etsy.com/shop/ThreadNinjasShop">
                    {" Etsy store "}
                </Link>
                for now.
            </Typography>
        </Grid>
    </Grid>
}

export default Home;